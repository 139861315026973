.detail {
  z-index: 2;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 1024px;
  background: white;
  opacity: 0;
  animation: fadeInSlideRight .3s forwards;
  flex-direction: column;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .detail {
    max-width: none;
    margin-left: 700px;
    width: calc(100% - 700px);
    transform: translateX(-20%);
  } 

  .detailFullScreen {
    max-width: none;
    margin-left: 0;
    width: 100%;
    transform: translateX(0%);
  }
}

.detailCloseIcon {
  fill: white;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.thumbs {
  position: relative;
  z-index: 1;
  height: 100%;
  background: white;
  overflow: auto;
}

.thumbsHeader {
  padding: 10px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
}

.thumbsNav {
  position: absolute;
}

.thumbsTitle {
  font-weight: var(--FONT_WEIGHT_MEDIUM);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 0 20px;
}

@media only screen and (min-width: 1024px) {
  .thumbImageContainer {
    position: relative;
  }

  .thumbImageContainerActive::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 4px solid rgb(var(--CREAM_CAN));
  }
}

@keyframes fadeInSlideRight {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (orientation:landscape) and (max-width: 1024px) {
  .detailFigcaption {
    display: none;
  }
}

.detailFigcaption {
  flex: 1 0;
}

.detailFigcaptionInner {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.detailTitle {
  font-weight: var(--FONT_WEIGHT_MEDIUM);
  font-size: 24px;
  display: flex;
  margin-bottom: 14px;
}

.detailDescription {
  color: rgb(var(--ROLLING_STONE));
}

.detailDescription p {
  margin-bottom: 8px;
}

.links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.detailImageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.detailImageContainerFullScreen {
  height: 100%;
}

.detailImageArrow {
  all: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: white;
  opacity: 0.8;
  cursor: pointer;
}

.detailImageArrow:last-child {
  left: auto;
  right: 0;
}

.detailImageArrow > svg {
  width: 60px;
  height: 60px;
}

.detailImageBack {
  background: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px 8px 10px;
  gap: 4px;
}

.detailImageClose {
  display: none;
}

.detailImageCloseIcon {
  fill: white;
  width: 70px;
  height: 70px;
}

@media only screen and (min-width: 1024px) {
  .detailImageClose {
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.detailFigcaptionFullScreenButton {
  display: none;
}

.detailFigcaptionFullScreen {
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 6px;
  font-size: 14px;
}

@media only screen and (min-width: 1024px) {
  .detailImageBack,
  .detailFigcaptionFullScreen {
    display: none;
  }

  .detailFigcaptionFullScreenButton {
    display: flex;
    margin-top: 20px;
  }
}

