.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  height: 100%;
  width: calc(100% - 30px);
  max-width: 700px;
  transition: opacity 200ms, transform 200ms;
  box-shadow: 10px 0 5px -2px rgba(0,0,0,.2);
  z-index: 1;
  overflow: auto;
}

@media only screen and (min-width: 750px) {
  .sidebar {
    width: 100%;
  }
}