.overview {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  padding-top: 20px;
  position: relative;
  background-color: #ababab;
  background-image: url(./header-bg.jpg);
  background-position: center;
  background-size: cover;
}

.header::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  height: 30px;
  width: 100%;
}

.logo {
  max-width: 150px;
  height: auto;
  margin: 0 auto;
}

@media only screen and (min-width: 1024px) {
  .logo {
    max-width: 250px;
  }
}

.title {
  color: white;
  font-size: 18px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
  line-height: 1.2;
  margin: 15px 20px 10px;
  display: block;
  text-align: center;
}

.results {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 1;
  color: rgb(var(--SHARK));
  padding: 20px 20px 10px;
  background: white;
}

.filterContainer > * {
  margin-bottom: 10px;
}

.filterButtonContainer {
  display: flex;
  align-items: center;
}

.filterButton {
  all: unset;
  cursor: pointer;
  border: none;
  padding: 0 16px;
  background: white;
  font-size: 18px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
}

.filterButton:first-child {
  margin-left: -16px;
  position: relative;
}

.filterButton:first-child::after {
  content: "";
  display: block;
  width: 0;
  height: 18px;
  border-right: 2px solid rgb(var(--SHARK));
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.filterButtonActive {
  color: rgb(var(--PIRATE_GOLD));
  text-decoration: underline;
  text-underline-offset: 8px;
}

.searchBarContainer {
  width: 100%;
  max-width: 300px;
  flex: 1 1 300px;
  position: relative;
}

.searchBarIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.searchBar {
  width: 100%;
  padding: 5px 10px 5px 35px;
  border: 1px solid rgb(var(--PIRATE_GOLD));
  border-radius: 4px;
}

.searchBar::placeholder {
  color: rgba(var(--ROLLING_STONE), .8);
}

.listContainer {
  overflow: auto;
  margin: 0 4px;
}

.listContainer::-webkit-scrollbar {
  border-radius: 8px;
  background: rgba(0, 0, 0, .1);;
  width: 8px;
}

.listContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, .3);
}

@media only screen and (min-width: 1024px) {
  .episode {
    margin-bottom: 10px;
  }
}

.episodeName {
  color: rgb(var(--SHARK));
  font-size: 18px;
  padding: 10px 20px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
}

.infoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
}

.infoContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 14px;
  border-radius: 999px;
}

.infoContainerOpen {
  background: white;
}

.infoText {
  font-size: 14px;
  line-height: 26px;
  visibility: hidden;
  text-align: center;
}

.infoTextOpen {
  visibility: visible;
}

.infoIcon {
  fill: currentColor;
  width: 20px;
  height: 20px;
}

.infoButton {
  all: unset;
  box-sizing: border-box;
  border: 1px solid currentColor;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.infoButtonOpen {
  color: black;
}