.leaflet-container {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.leaflet-tile-pane {
  filter: sepia(.5);
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-content-wrapper {
  overflow: hidden;
  padding: 0;
  border-radius: 8px;
}

.leaflet-popup-content p {
  margin: 0;
}