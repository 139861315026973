.imageContainer {
  aspect-ratio: 16 / 9;
  background: #f7f5ea;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageContainerFullScreen {
  height: 100%;
  aspect-ratio: auto;
}
  
.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
  color: transparent;
}

.image::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7f5ea;
}

.imageBg {
  filter: blur(20px);
  opacity: .9;
  position: absolute;
  max-width: none;
  min-width: 105%;
  margin: -20px;
}

.imageDynamicObjectFit {
  width: auto;
}