.mapContainer {
  height: 100%;
  width: 100%;
  isolation: isolate;
}

.popupInner {
  min-width: 300px;
  width: 100%;
}

.popupContent {
  padding: 10px 20px 15px 10px;
  font-size: 14px;
}

.popupTitle {
  font-size: 18px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
  margin-bottom: 10px;
}

.popupSubTitle {
  margin: -5px 0 10px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
}

.popupEpisodes {
  margin-bottom: 30px;
  column-count: 2;
}

.popupText {
  margin: 0 0 30px;
  color: rgb(var(--ROLLING_STONE));
}

.popupEpisodes:has(:only-child) {
  column-count: 1;
}

.popupLinkContainer {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}