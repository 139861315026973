.location {
  padding: 15px 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: rgb(var(--ROLLING_STONE));
}

@media only screen and (min-width: 1024px) {
  .location {
    padding: 25px 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .nestedLocation {
    margin-left: 70px;
  }
}

.location:not(:last-child) {
  border-bottom: 1px solid rgb(var(--PIRATE_GOLD));
}

.location:hover {
  background: rgba(var(--PIRATE_GOLD), .06)
}

.markerIcon {
  align-self: flex-start;
}

.locationText {
  flex: 1;
}

.locationName {
  color: rgb(var(--SHARK));
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
}

.locationImageContainer {
  flex: 0 0 100px;
  width: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.locationEpisodeList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

@media only screen and (min-width: 1024px) {
  .locationEpisodeList {
    margin-top: 25px;
  }
}

.locationEpisodeListItem:not(:last-child)::after {
  content: "·";
  margin: 0 5px;
}

.locationDescription p {
  word-break: break-word;
  color: rgb(var(--ROLLING_STONE));
}