.content {
  overflow: auto;
}

.nav {
  padding: 20px;
  position: sticky;
  top: 0;
  background: white;
}

.headerImageContainer {
  background: black;
  position: relative;
}

.galleryLink {
  color: white;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0,0,0,.5);
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.galleryLinkIcon {
  width: 20px;
  height: 20px;
}

.content {
  padding: 20px;
}

.episodes {
  list-style: inside;
}

.locationName {
  font-size: 24px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
  margin-bottom: 10px;
}

.locationDescription {
  margin-bottom: 40px;
  color: rgb(var(--ROLLING_STONE));
}

.locationDescription p {
  margin-bottom: 8px;
}

.lists {
  display: flex;
  gap: 20px;
}

.listContainer {
  flex: 1 1 50%;
}

.list {
  line-height: 20px;
  color: rgb(var(--ROLLING_STONE));
}

.listTitle {
  font-weight: var(--FONT_WEIGHT_MEDIUM);
  font-size: 18px;
}

.listFooter {
  margin-top: 20px;
}

.mapLink {
  width: 22px;
  height: 22px;
  margin-left: 5px;
}

.areasOfInterest {
  margin: 20px -20px 0;
}

.areasOfInterestTitle {
  color: rgb(var(--SHARK));
  font-size: 18px;
  padding: 10px 20px;
  font-weight: var(--FONT_WEIGHT_MEDIUM);
}