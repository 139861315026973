.sidebarContainer { 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 700px;
  transition: transform 200ms;
}

.collapsed {
  transform: translateX(-100%);
}

.collapsed .sidebar {
  visibility: hidden;
  opacity: 0;
}

.toggleButton {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 80px;
  width: 30px;
  border: 1px solid rgba(0,0,0,.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.collapsed .toggleButton {
  display: flex;
  transform: translateY(-50%) translateX(30px);
}

@media only screen and (min-width: 750px) {
  .toggleButton {
    display: flex;
    top: 50%;
    width: 40px;
    transform: translateY(-50%) translateX(40px);
  }

  .collapsed .toggleButton {
    transform: translateY(-50%) translateX(40px);
  }
}

.toggleIcon {
  height: 30px;
  width: 30px;
}

.collapsed .toggleIcon {
  transform: rotate(180deg);
}

.gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: red;
}