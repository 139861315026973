*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}
ul {
  list-style: none;
  padding: 0;
}
button {
  cursor: pointer;
}

:root {
  --PIRATE_GOLD: 174, 146, 0;
  --SHARK: 32, 33, 36;
  --ROLLING_STONE: 112, 117, 122;
  --CREAM_CAN: 242, 201, 76;

  --FONT_WEIGHT_MEDIUM: 500;
}

.link {
  all: unset;
  color: rgb(var(--PIRATE_GOLD));
  cursor: pointer;
  display: flex;
  align-items: center;
}

.link-column {
  flex-direction: column;
}

.link-icon {
  width: 22px;
  height: 22px;
}

p,
li {
  color: rgb(var(--ROLLING_STONE));
}

a {
  color: rgb(var(--PIRATE_GOLD));
  font-weight: var(--FONT_WEIGHT_MEDIUM);
}

.bounce {
  animation-duration: .8s;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes bounce {
  0%   { top: 0; }
  10%  { top: 0;  }
  30%  { top: -6px; }
  50%  { top: 0; }
  57%  { top: -2px; }
  64%  { top: 0; }
  100% { top: 0; }
}